<template>
  <section class="boardWrap">
    <div class="pointinwrap">
      <!--p class="pointname"><strong class="rdc">{{userData.memNick}}</strong>님의 포인트전환 정보입니다.</p-->
      <p class="havepoint">{{$t('front.point.ablePoint')}}</p>
      <ul class="havepointchng">
        <li><img src="@/assets_mobile/img/icon_point.svg" />{{thousand(sum ? sum.pointAmt: 0)}}</li>
        <li><a>{{$t('front.board.moneychanges')}}</a></li>
      </ul>
      <ul class="havepointwrap">
        <li><em>{{$t('front.point.monthPoint')}}</em><p><span>{{thousand(sum ? sum.thisMonthPoint : 0)}}</span>P</p></li>
        <li><em>{{$t('front.point.allPoint')}}</em><p><span>{{thousand(sum ? sum.totalInPoint : 0)}}</span>P</p></li>
        <li><em>{{$t('front.point.allUsePoint')}}</em><p><span>{{thousand(sum ? sum.totalOutPoint : 0)}}</span>P</p></li>
      </ul>
      <span class="prvpoint">{{$t('front.point.prevMonthPoint')}} : {{thousand(sum ? sum.preMonthPoint: 0)}}P</span>
      <!--ul class="pointinlist">
        <li>
          <span>잔여 포인트</span>
          <span class="pt"><em class="orc">{{thousand(sum ? sum.pointAmt : 0)}}</em>P</span>
        </li>
        <li>
          <span>당월 적립포인트</span>
          <span class="pt"><em class="orc">{{thousand(sum ? sum.thisMonthPoint : 0)}}</em>P</span>
        </li>
      </ul>
      <ul class="pointinlist">
        <li>
          <span>전월 적립포인트</span>
          <span class="pt"><em class="orc">{{thousand(sum ? sum.preMonthPoint : 0)}}</em>P</span>
        </li>
        <li>
          <span>총 누적포인트</span>
          <span class="pt"><em class="orc">{{thousand(sum ? sum.totalInPoint : 0)}}</em>P</span>
        </li>
        <li>
          <span>총 사용포인트</span>
          <span class="pt"><em class="orc">{{thousand(sum ? sum.totalOutPoint : 0)}}</em>P</span>
        </li>
      </ul-->
    </div>

    <date-filter-mobile @search="loadList"
                        @update="onChangeDate"
                        />

    <div class="gamebetwrap mb100">
      <template v-if="list.length > 0">
        <div class="gamebetlist" v-for="item in list" v-bind:key="item.updDt">
          <a class="close"><img src="@/assets_mobile/img/icon_delete.svg" /></a>
          <ul class="gamebetdetail useaddpointwrap">
            <li class="useaddpoint chngp">
              <em>{{$t('front.board.changes')}}</em>
              <span><em>{{thousand(item.point_inAmt.replace('-', ''))}}</em>P</span>
            </li>
            <li class="useaddpointcon">
              <span>{{dateFormatAll(item.regDt)}}</span>
              <span>{{item.codeName}}</span>
            </li>
          </ul>
          <!--ul class="gamebetdetail w100-50">
            <li><span>신청포인트</span> : {{thousand(item.point_inAmt.replace('-', ''))}}</li>
            <li><span>사용구분</span> : 보유머니전환</li>
            <li><span>신청일시</span> : {{dateFormatAll(item.regDt)}}</li>
            <li><span>처리일시</span> : {{dateFormatAll(item.udpDt)}}</li>
          </ul>
          <a class="done">완료</a-->
        </div>
      </template>
      <template v-else>
        <div class="gamebetlist">
          <ul class="gamebetdetail">
            {{$t('front.common.notFoundList')}}
          </ul>
        </div>
      </template>
    </div>
  </section>
    <pagination-mobile :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      @goToPage="loadList" />
</template>

<script>
import pointUseList from '@/views/member/mypage/point/uselist'
import PaginationMobile from '@/components/ui/PaginationMobile'
import DateFilterMobile from '@/components/ui/DateFilterMobile'

export default {
  name: 'mobilePointUseList',
  components: {
    DateFilterMobile,
    PaginationMobile
  },
  mixins: [
    pointUseList
  ]
}
</script>

<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
